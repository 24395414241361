import { combineReducers } from "redux";
import entities from './entities';

export const buildReducer = () => {
  return combineReducers({
    entities,
  })
}

export default combineReducers({
  entities,
});