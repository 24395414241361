import React, { useState } from 'react'
const PlanContext = React.createContext(null);

export const PlanProvider = ({ planData, children }) => {
  let [plan, setPlan] = useState(planData);
  return (
    <PlanContext.Provider value={{ plan, setPlan }}>
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => React.useContext(PlanContext);