import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';

export const FETCH_REQUEST_STATISTIC = 'FETCH_REQUEST_STATISTIC';

export const RECEIVE_REQUEST_STATISTIC = 'RECEIVE_REQUEST_STATISTIC';

const defaultState = {
  data: {},
  isFetching: false
};

const dis = (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_STATISTICS:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      }
    case FETCH_REQUEST_STATISTIC:
      return fetchRequest(state);
    case RECEIVE_REQUEST_STATISTIC:
      return receiveRequest(state);
    default:
      return state;
  }
};

export default dis