import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const RECEIVE_CREDITS = 'RECEIVE_CREDITS';

export const FETCH_REQUEST_CREDITS = 'FETCH_REQUEST_CREDITS';

export const RECEIVE_REQUEST_CREDITS = 'RECEIVE_REQUEST_CREDITS';

const defaultState = {
  post_credits: 0,
  word_credits: 0,
  history: [],
  isFetching: false
};

const dis = (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_CREDITS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      }
    case FETCH_REQUEST_CREDITS:
      return fetchRequest(state);
    case RECEIVE_REQUEST_CREDITS:
      return receiveRequest(state);
    default:
      return state;
  }
};

export default dis