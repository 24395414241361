import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const RECEIVE_IDEAS = 'RECEIVE_IDEAS';

export const RECEIVE_IDEA = 'RECEIVE_IDEA';

export const REMOVE_IDEA = 'REMOVE_IDEA';

export const FETCH_REQUEST_IDEA = 'FETCH_REQUEST_IDEA';

export const RECEIVE_REQUEST_IDEA = 'RECEIVE_REQUEST_IDEA';

const defaultState = {
  byId: {},
  all: [],
  isFetching: false
};

const add = (state, user) => ({
  ...state,
  byId: {
    ...state.byId,
    ...user
  },
  all: [
    ...new Set(state.all.concat(Object.keys(user)))
  ]
});

const remove = (state, id) => {
  const items = {}
  Object.values(state.byId)
    .filter(element => element.id !== id)
    .forEach((element) => {
      items[element.id] = element
    })
  return({
    ...state,
    byId: items,
    all: [...new Set(state.all.concat(Object.keys(items)))]
  })
}

const dis = (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_IDEAS:
      return {
        ...state,
        byId: action.payload,
        all: [
          ...new Set(Object.keys(action.payload))
        ],
        isFetching: false
      }
    case RECEIVE_IDEA:
      return { ...add(state, action.payload), isFetching: false }
    case REMOVE_IDEA:
      return remove(state, action.payload);
    case FETCH_REQUEST_IDEA:
      return fetchRequest(state);
    case RECEIVE_REQUEST_IDEA:
      return receiveRequest(state);
    default:
      return state;
  }
};

export default dis