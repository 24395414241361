import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary'
import { Loader } from 'rsuite';
import { Provider } from "react-redux";
import { CustomProvider } from 'rsuite';
import configureStore from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from './context/auth.context';
import { PlanProvider } from './context/plan.context';
import mixpanel from "mixpanel-browser";
import CookieConsent from "react-cookie-consent";
import config from 'app_config'
import './index.css';
import './styles/index.less';
import 'react-image-lightbox/style.css';
import reportWebVitals from './reportWebVitals';
import { initFacebookSdk } from 'helpers/facebook';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { FirebaseProvider } from 'context/firebase.context';

if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_FEATURE_ENABLE_MIXPANEL_TRACKING === "true") {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
}

const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
const theme = localStorage.getItem("theme")

const ErrorFallback = ({error, resetErrorBoundary}) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const SuspenseLoader = () => (
  <Loader center content="Loading..." />
)

const errorHandler = (error, info) => {
  console.log(error, info)
}

const AppRouter = React.lazy(() => import("./routers/Router"));
const appStore = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
const startApp = () => {
  root.render(
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <Provider store={appStore}>
          <CustomProvider theme={theme}>
            <Suspense fallback={<SuspenseLoader/>} >
              <Router>
                {/* <FirebaseProvider> */}
                  <AuthProvider userData={user}>
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                      <PlanProvider>
                        <AppRouter />
                        <CookieConsent
                          buttonText="Accept all cookies"
                          buttonStyle={{ background: "#ff9900", fontSize: "13px", color: "#FFFFFF" }}
                        >
                          We use cookies and other tracking technologies to improve your browsing experience on our website,
                          to analyze our website traffic, and to understand where our users are coming from.
                        </CookieConsent>
                      </PlanProvider>
                    </GoogleOAuthProvider>
                  </AuthProvider>
                {/* </FirebaseProvider> */}
              </Router>
            </Suspense>
          </CustomProvider>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

if (config.REACT_APP_FEATURE_FACEBOOK_LOGIN)
  initFacebookSdk().then(startApp);
else
  startApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
