const app_config = {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  REACT_APP_APP_BASE_URL: process.env.REACT_APP_APP_BASE_URL,

  REACT_APP_WS_ROOT: process.env.REACT_APP_WS_ROOT,
  
  REACT_APP_API_ROOT_URL: process.env.REACT_APP_API_ROOT_URL,
  REACT_APP_SHARED_LINK_URL: process.env.REACT_APP_SHARED_LINK_URL,
  REACT_APP_PAYMONGO_PUBLIC_KEY: process.env.REACT_APP_PAYMONGO_PUBLIC_KEY,
  REACT_APP_GEOLOCATION_API_KEY: process.env.REACT_APP_GEOLOCATION_API_KEY,

  REACT_APP_ID: process.env.REACT_APP_ID,
  REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL: process.env.REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET: process.env.REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,

  REACT_APP_PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_MIXPANEL_KEY: process.env.REACT_APP_MIXPANEL_KEY,

  REACT_APP_FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,

  REACT_APP_LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  REACT_APP_LINKEDIN_CLIENT_SECRET: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,

  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,

  REACT_APP_HOTJAR_HJID: process.env.REACT_APP_HOTJAR_HJID,
  REACT_APP_HOTJAR_HJSV: process.env.REACT_APP_HOTJAR_HJSV,

  REACT_APP_FEATURE_FACEBOOK_LOGIN:
    process.env.REACT_APP_FEATURE_FACEBOOK_LOGIN === "true",
  REACT_APP_FEATURE_LINKEDIN_LOGIN:
    process.env.REACT_APP_FEATURE_LINKEDIN_LOGIN === "true",
  REACT_APP_FEATURE_POST_MODAL:
    process.env.REACT_APP_FEATURE_POST_MODAL === "true",
  REACT_APP_GEOLOCATION_ENABLED: process.env.REACT_APP_GEOLOCATION_ENABLED === "true",
  REACT_APP_FEATURE_INVITATION: process.env.REACT_APP_FEATURE_INVITATION === "true"
};

export default app_config