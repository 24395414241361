import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';

export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const FETCH_REQUEST_NOTIFICATION = 'FETCH_REQUEST_NOTIFICATION';

export const RECEIVE_REQUEST_NOTIFICATION = 'RECEIVE_REQUEST_NOTIFICATION';

const defaultState = {
  byId: {},
  all: [],
  isFetching: false
};

const add = (state, user) => ({
  ...state,
  byId: {
    ...state.byId,
    ...user
  },
  all: [
    ...new Set(state.all.concat(Object.keys(user)))
  ]
});

const addItems = (state, items) => {
  let newState = state;

  Object.keys(items).forEach(id => {
    newState = add(newState, { [id]: items[id] });
  });

  return newState;
};

const remove = (state, id) => {
  const items = {}
  Object.values(state.byId)
    .filter(element => element.id !== id)
    .forEach((element) => {
      items[element.id] = element
    })
  return({
    ...state,
    byId: items,
    all: [...new Set(state.all.concat(Object.keys(items)))]
  })
}

const dis = (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_NOTIFICATIONS:
      return { ...addItems(state, action.payload), isFetching: false };
    case RECEIVE_NOTIFICATION:
      return { ...add(state, action.payload), isFetching: false }
    case REMOVE_NOTIFICATION:
      return remove(state, action.payload);
    case FETCH_REQUEST_NOTIFICATION:
      return fetchRequest(state);
    case RECEIVE_REQUEST_NOTIFICATION:
      return receiveRequest(state);
    default:
      return state;
  }
};

export default dis