import { combineReducers } from "redux";
import activities from './activities';
import assets from './assets';
import brands from './brands';
import campaigns from './campaigns';
import comments from './comments';
import creators from './creators';
import content_requests from './content_requests';
import content_proposals from './content_proposals';
import content_proposal_items from './content_proposal_items';
import credits from './credits';
import customers from './customers'
import ideas from './ideas';
import invitations from './invitations';
import notifications from './notifications';
import organizations from './organizations';
import posts from './posts';
import portfolio from './portfolio';
import profiles from './profiles';
import products from './products';
import roles from './roles';
import revisions from './revisions';
import statistics from './statistics';
import socials from './socials';
import suggestions from './suggestions';
import subscriptions from './subscriptions';
import tasks from './tasks';
import themes from './themes';
import user from './user';

export default combineReducers({
  activities,
  assets,
  brands,
  campaigns,
  content_requests,
  content_proposals,
  content_proposal_items,
  comments,
  creators,
  credits,
  customers,
  ideas,
  invitations,
  notifications,
  organizations,
  posts,
  portfolio,
  profiles,
  products,
  revisions,
  roles,
  statistics,
  suggestions,
  socials,
  subscriptions,
  tasks,
  themes,
  user,
});