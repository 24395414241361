import React, { useState } from 'react'
const AuthContext = React.createContext(null);

export const AuthProvider = ({ userData, children }) => {
  const [user, setUser] = useState(userData);
  const [roles, setRoles] = useState([]);

  const hasRole = (role, resource, resource_id) => {
    return roles?.filter(item => {
      return (item.name === role && 
        item.resource_type === resource &&
        item.resource_id === resource_id)
    })?.length > 0
  }
  
  const hasRoles = (r, resource, resource_id) => {
    return !!roles?.filter(item => {
      return (r.includes(item.name) && 
        item.resource_type === resource &&
        item.resource_id === resource_id)
    })?.length
  }
  return (
    <AuthContext.Provider value={{ user, setUser, roles, setRoles, hasRole, hasRoles }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);