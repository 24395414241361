import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const RECEIVE_ACTIVITIES = 'RECEIVE_ACTIVITIES';

export const RECEIVE_ACTIVITY = 'RECEIVE_ACTIVITY';

export const FETCH_REQUEST_ACTIVITY = 'FETCH_REQUEST_ACTIVITY';

export const RECEIVE_REQUEST_ACTIVITY = 'RECEIVE_REQUEST_ACTIVITY';

const defaultState = {
  byId: {},
  all: [],
  isFetching: false
};

const add = (state, user) => ({
  ...state,
  byId: {
    ...state.byId,
    ...user
  },
  all: [
    ...new Set(state.all.concat(Object.keys(user)))
  ]
});

const dis = (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_ACTIVITIES:
      return {
        ...state,
        byId: action.payload,
        all: [
          ...new Set(Object.keys(action.payload))
        ],
        isFetching: false
      }
    case RECEIVE_ACTIVITY:
      return { ...add(state, action.payload), isFetching: false }
    case FETCH_REQUEST_ACTIVITY:
      return fetchRequest(state);
    case RECEIVE_REQUEST_ACTIVITY:
      return receiveRequest(state);
    default:
      return state;
  }
};

export default dis